export default defineNuxtRouteMiddleware((to, from) => {
  const { $auth } = useNuxtApp();

  if (!$auth.loggedIn) {
    const utms = Object.entries(to.query).reduce(
      (utms: Record<string, string | null>, [key, value]) => {
        if (key.startsWith('utm_')) utms[key] = value as string;
        return utms;
      },
      {}
    );

    return navigateTo({
      path: '/login',
      query: { redirectTo: to.query.redirectTo || to.fullPath, ...utms },
    });
  }
});
